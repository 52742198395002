import React, { FC } from 'react';

import RichText from '@components/RichText/RichText';
import { useLinkComponent } from '@link';
import { mapImage, wrap } from '@sitecore/common';
import { TextImageRebrandedRendering } from '@sitecore/types/TextImageRebranded';
import { Bleed, Box, ButtonLink, Grid, Heading, Image, Stack, Stretch, Text, TextLink } from '@sparky';

const TextImageRebranded: FC<TextImageRebrandedRendering> = ({ params, fields }) => {
  const { buttonLink, content, link, title } = fields;
  const isReversed = params?.imagePosition === 'right';

  const imageProps = mapImage(fields?.image);
  const Link = useLinkComponent();

  const gridColumns = {
    left: { initial: '1 / -1', lg: isReversed ? '7 / 13' : '1 / 7', xl: isReversed ? '8 / 13' : '1 / 6' },
    right: { initial: '1 / -1', lg: isReversed ? '1 / 8' : '6 / 13', xl: isReversed ? '1 / 9' : '5 / 13' },
  };

  return (
    <Grid columns="12" gridTemplateRows="1fr" alignY="center" gap={{ initial: '0', lg: '4', xl: '6' }}>
      <Grid.Item
        gridColumn={gridColumns.left}
        gridRow={{ lg: '1 / -1' }}
        order={{
          initial: 2,
          lg: 2,
        }}>
        <Bleed left={{ initial: '8', md: '12', lg: '0' }} vertical={{ initial: '8', lg: '0' }}>
          <Box paddingTop={{ initial: '0', lg: '4' }}>
            <Grid columns="12">
              <Grid.Item gridColumn={{ initial: '1/13' }}>
                <Stack>
                  <Box
                    backgroundColor={'backgroundBrand'}
                    borderRadius="l"
                    borderBottomLeftRadius={{ initial: 'none', lg: 'l' }}
                    borderTopLeftRadius={{ initial: 'none', lg: 'l' }}
                    paddingLeft={{ initial: '8', md: '12', lg: '8' }}
                    padding="8">
                    <Heading as={'h2'} size="M" color="textInverted">
                      {title?.value}
                    </Heading>
                  </Box>
                </Stack>
              </Grid.Item>
              <Grid.Item gridColumn={{ initial: '1/13', lg: isReversed ? '3/13' : '1/11' }}>
                <Box
                  paddingLeft={{ initial: '8', md: '12', lg: isReversed ? '4' : '8' }}
                  padding="8"
                  paddingRight={{ initial: '0', lg: '8' }}>
                  <Stack gap="6">
                    <Text size={{ initial: 'BodyM', md: 'BodyL' }}>
                      {wrap(
                        content,
                        <RichText
                          html={content?.value}
                          replacements={{
                            a: props => <TextLink {...props}>{props.children}</TextLink>,
                            strong: props => (
                              <Text display="inline" weight="bold">
                                {props.children}
                              </Text>
                            ),
                          }}
                        />,
                      )}
                    </Text>
                    {((buttonLink?.value?.text && buttonLink.value.href) || buttonLink?.editable) && (
                      <Stack.Item grow={false}>
                        <Stretch width={{ initial: true, lg: false }}>
                          <Link
                            editable={buttonLink.editable}
                            linkType={buttonLink.value.linktype}
                            linkValue={buttonLink.value}>
                            <ButtonLink target={buttonLink.value.target}>{buttonLink.value.text} </ButtonLink>
                          </Link>
                        </Stretch>
                      </Stack.Item>
                    )}
                    {((link?.value?.href && link.value.text) || link?.editable) && (
                      <Stack.Item grow={false}>
                        <Link editable={link.editable} linkType={link.value.linktype} linkValue={link.value}>
                          <TextLink emphasis="high" target={link.value.target}>
                            {link.value.text}
                          </TextLink>
                        </Link>
                      </Stack.Item>
                    )}
                  </Stack>
                </Box>
              </Grid.Item>
            </Grid>
          </Box>
        </Bleed>
      </Grid.Item>
      <Grid.Item
        gridColumn={gridColumns.right}
        order={{ initial: -1, lg: isReversed ? -1 : 1 }}
        gridRow={{ lg: '1 / -1' }}>
        {imageProps.src ? (
          <Bleed right={{ initial: '6', md: '10', lg: '0' }}>
            <Image
              alt={imageProps.alt}
              aspectRatio="4/3"
              borderRadius={{ initial: ['rounded', 'square', 'square', 'rounded'], lg: 'rounded' }}
              height="100%"
              objectFit="cover"
              sources={imageProps.sources}
              src={imageProps.src}
              width="100%"
            />
          </Bleed>
        ) : null}
      </Grid.Item>
    </Grid>
  );
};

export default TextImageRebranded;
